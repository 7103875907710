// AOS
import AOS from 'aos';


// inline scss
import '../scss/app.scss';

// jQuery
import $ from 'jquery';
window.$ = $;

import './maginific/jquery.magnific-popup.js';

// Slick slider implementation
// @doc http://kenwheeler.github.io/slick/
import 'slick-carousel';

// SHOW CONTACT FORM
window.toogleContactForm = function () {
    $('.contact-form-collection').slideToggle("slow");
    $(document).scrollTop($("#form-collection").offset().top);
}

function showNewsletterForm() {
    $("#newsletter-banner").addClass('banner-show');
    $('#newsletter-banner span.title-banner').css('opacity', '0');
}

function hideNewsletterForm() {
    $("#newsletter-banner").addClass('banner-hide');
    $("#newsletter-banner").removeClass('banner-show');
    $('#newsletter-banner span.title-banner').css('opacity', '1');
    $("#newsletter-banner").addClass('banner-hide');
}

$(() => {

    var timeoutNewsletterPopin;

// SHOW CONTACT FORM ON PRODUCT PAGE
$(".contact-form-collection").hide();

$(".swatch").click(function () {
    setTimeout(function () {
        if ($("#form-collection").length > 0) {
            $(".contact-form-collection").hide();
        }
    });
});

// SHOW NEWSLETTER ON MOUSEOVER
$("#newsletter-banner").mouseover(function () {
    showNewsletterForm();
});
hideNewsletterForm();
// HIDE NEWSLETTER ON MOUSEOUT
$("#newsletter-banner").mouseout(function () {
    timeoutNewsletterPopin = window.setTimeout(function () {
        hideNewsletterForm();
    }, 6000);

    $("#newsletter-banner").mouseover(function () {
        clearTimeout(timeoutNewsletterPopin);
    });
});

// HIDE NEWSLETTER AFTER A TIME
//<-- Delay in milliseconds
//
//
//var bannerNewsletter = $('#newsletter-banner');
//bannerNewsletter.addClass('banner-hide');
//setTimeout(function() {
//    bannerNewsletter.removeClass('banner-show');
//}, 2000);

// SLICK SLIDER NEWS SPECIFIC
$('.slick-slider-news').slick({
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    fade: true,
    fadeSpeed: 1000,
    nextArrow: '<span class="arrow arrow-right"></span>',
    prevArrow: '<span class="arrow arrow-left"></span>',
});
// SMOOTH SCROLL ON DIV
$("#go-maps").click(function () {
    $('html,body').animate({
            scrollTop: $("#maps").offset().top
        },
        'slow');
});
$("#link-adress").click(function () {
    $('html,body').animate({
            scrollTop: $("contact#maps").offset().top
        },
        'slow');
});
$("#localisation").click(function () {
    $('html,body').animate({
            scrollTop: $("contact#maps").offset().top
        },
        'slow');
});
$("a#form-go").click(function () {
    $('html,body').animate({
            scrollTop: $("contact#form-contact").offset().top
        },
        'slow');
});
$("#product-anchor").click(function () {
    $('html,body').animate({
            scrollTop: $("eshop#content").offset().top
        },
        'slow');
});
$(".more-details").click(function () {
    $('html,body').animate({
            scrollTop: $("#details-product").offset().top
        },
        'slow');
});
$(".anchor").click(function () {
    $('html,body').animate({
            scrollTop: $("#maps").offset().top
        },
        'slow');
});

// AOS INIT
/*$('h2.title').attr('data-aos', 'fade-in');
AOS.init();

$('.slick-slider-news').magnificPopup({
    delegate: 'a', // child items selector, by clicking on it popup will open
    type: 'image',
    gallery: {enabled: true}
    // other options
});*/

//TIDIO TRANSLATE

document.tidioChatLang = document.querySelector("html").getAttribute("lang");

if ($('#newsletter-banner').length) {
    if ($('#newsletter-banner').find('.mc4wp-alert').length) {
        showNewsletterForm();
        window.scrollTo(0, 0);
        timeoutNewsletterPopin = window.setTimeout(function () {
            hideNewsletterForm();
        }, 6000);
    }
}
if ($('.footer-newsletter').find('.mc4wp-alert').length) {
    window.scrollTo(0, $(document).height());

}
})
;



